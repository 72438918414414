import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Layout } from '../components/Layout';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { getOrder } from '../service/getOrder';
import { OrderItem } from '../components/OrderItem';

export default function Order({ pageContext: { data } }) {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);

  const fetchAndSetOrder = async () => {
    const res = await getOrder(id).catch((e) => console.log(e));
    setOrder(res);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (id) fetchAndSetOrder();
    else setLoading(false);
  }, [id]);

  if (!order && loading)
    return (
      <Layout>
        <section className='max-w-screen-xl flex flex-col m-auto mb-12 p-6 items-center'>
          <h1 className='text-2xl text-center mb-8'>Fetching Order</h1>
          <StaticImage
            className='spin h-32 w-32 rounded-full sm:mb-0'
            src='../img/logo.jpg'
            alt='logo'
            layout='fixed'
          />
        </section>
      </Layout>
    );

  if (!order && !loading)
    return (
      <Layout>
        <section className='max-w-screen-xl flex flex-col items-center m-auto mb-12 p-6'>
          <h2 className='text-2xl text-center mb-8'>
            Could Not Find Order Id {id ? id : '"No Id provided"'}
          </h2>
          <a href='/'>
            <StaticImage
              className='h-32 w-32 rounded-full sm:mb-0'
              src='../img/logo.jpg'
              alt='logo'
              layout='fixed'
            />
          </a>
        </section>
      </Layout>
    );

  const {
    created,
    total,
    subtotal,
    orderNumber,
    processedDate,
    fulfillmentStatus,
    discounts,
    shipping,
    lineItems,
    firstName,
  } = order;

  const lineItemEls = lineItems.map((item) => {
    const product = data.nodes.find((node) => node.title === item.title);
    return <OrderItem key={item.id} item={item} product={product} />;
  });

  return (
    <Layout>
      <section className='max-w-screen-xl m-auto mb-12 p-6'>
        <h2 className='text-2xl text-center mb-8'>Order {orderNumber}</h2>
        <h3 className='text-2xl text-center mb-8'>Thank you {firstName}!</h3>
        <h3 className='text-2xl text-center mb-8'>
          Status:
          <span className='text-blue-500'>
            {fulfillmentStatus ? ' Shipped!' : ' Preparing for Shipment'}
          </span>
        </h3>
        <div className='p-6'>
          <h3 className='text-lg'>Items</h3>
          <hr className='m-2' />
          <ul className='flex flex-wrap flex-col justify-center max-w-screen-lg m-auto'>
            {lineItemEls}
          </ul>

          <div className='flex flex-col items-end'>
            <div className='flex flex-col items-end'>
              <p className='text-blue-500 text-md mb-4'>Subtotal - {subtotal.toFixed(2)}</p>
              <p className='text-blue-500 text-sm mb-4'>Shipping - {shipping.toFixed(2)}</p>
              {!!discounts && <p>Discount: -{discounts.toFixed(2)}</p>}
              <p className='text-blue-500 text-xl mb-4'>Total: {total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
