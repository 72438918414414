import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const OrderItem = ({ item, product }) => {
  return (
    <li className='flex flex-col sm:flex-row items-center justify-between w-full mb-4'>
      <div className='flex flex-col sm:flex-row items-center'>
        <div className='flex items-center'>
          <GatsbyImage
            className='w-32 mb-4 sm:mb-0 sm:mr-8'
            image={product.images[0].localFile.childImageSharp.gatsbyImageData}
            alt=''
            placeholder='blurred'
          />
        </div>
        <p>
          {item.title}: {item.quantity}
        </p>
      </div>
      <p>{(item.price * item.quantity).toFixed(2)}</p>
    </li>
  );
};
