export const getOrder = async (id) => {
  const port = '58191'
  const url = process.env.GATSBY_ENV === 'dev' 
    ? `http://localhost:${port}/.netlify/functions/shopify` 
    : '/.netlify/functions/shopify'

  const order = await fetch(`${url}?orderid=${id}`)
    .then(async(res) => res.json())
    .catch(e => ({ error: e }));

  if (!order.error) return order
}